<template>
  <el-menu
    default-active="1-4-1"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :collapse="isCollapse"
    background-color="#df6124"
    text-color="#fff"
    :unique-opened=true
    active-text-color="#663300"
  >
  <h3>{{ isCollapse ? '热力' : '热力系统' }}</h3>
    <el-menu-item
      @click="clickMenu(item)"
      v-for="item in noChildren"
      :key="item.name"
      :index="item.name"
    >
      <i :class="`el-icon-${item.icon}`"></i>
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>
    <el-submenu :index="'/' + item.id" v-for="item in hasChilren" :key="item.id" >
      <template slot="title">
        <i :class="`el-icon-${item.icon}`"></i>
        <span slot="title">{{ item.label }}</span>
      </template>
      <el-menu-item-group v-for="subItem in item.childern" :key="subItem.path">
        <el-menu-item @click="clickMenu(subItem)" :index="subItem.path">{{subItem.label}}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>
  
  <script>
export default {
  data() {
    return {
      menuData: [
        {
          id:1,
          path: "/home",
          name: "home",
          label: "项目首页",
          icon: "s-home",
          url: "Home",
        },
        // {
        //   path: "/mall",
        //   name: "mall",
        //   label: "商品管理",
        //   icon: "s-shop",
        //   url: "Mall",
        // }, 
        {
          id:2,
          label: "统计用量",
          icon: 's-data',
          childern: [
            {
              path: "/daily",
              name: "daily",
              label: "日用量",
              icon: "setting",
              url: "Dosage/daily",
            },
            {
              path: "/usage",
              name: "usage",
              label: "月用量",
              icon: "setting",
              url: "Dosage/usage"
            },{
              path: "/Annual",
              name: "Annual",
              label: "年用量",
              icon: "setting",
              url: "Dosage/Annual"
            },
          ],
        },
        {
          id:3,
          label: "基础资料",
          icon: 'menu',
          childern: [
            {
              path: "/place",
              name: "place",
              label: "位置信息",
              icon: "setting",
              url: "Basics/place",
            },
            {
              path: "/Gauge",
              name: "Gauge",
              label: "表具类型",
              icon: "setting",
              url: "Basics/Gauge"
            },{
              path: "/archives",
              name: "archives",
              label: "热表档案",
              icon: "setting",
              url: "Basics/archives"
            },{
              path: "/collector",
              name: "collector",
              label: "采集器",
              icon: "setting",
              url: "Basics/collector"
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    clickMenu (item) {
      console.log(item)
      // 当页面的路由与跳转的路由不一致才允许跳转------------$route是当前页面的路由，$router是整个路由实例
      if (this.$route.path !== item.path && !(this.$route.path === '/user' && (item.path === '/'))) {
        this.$router.push(item.path)
      }
    },
  },
  computed: {
    //没有子菜单
    noChildren() {
      return this.menuData.filter((item) => !item.childern);
    },
    //有子菜单
    hasChilren() {
      return this.menuData.filter((item) => item.childern);
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse
    }
  },
};
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
  font-size: 16px;
  font-weight: 400;
}
.el-menu{
  height: 100vh;
  border-right: none;
  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
  }
  i{
    color: #fff;
  }
}
</style>