import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../views/login/login'
import Main from '../views/Main.vue'
import Place from '../views/Basics/place.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component:  () => import('@/views/Login')
  }, {
    path: '/main',
    component: Main,
    redirect: '/home',
    children: [ 
      {
        path: '/home',
        name: 'Home',
        component:  () => import('@/views/Home')
      },//项目首页
      {
        path: '/mall',
        component:  () => import('@/views/Mall')
      },//商品管理
      { 
        path: '/place', 
        component: Place
      },//位置信息
      {
        path: '/Gauge', 
        component: () => import('@/views/Basics/Gauge.vue')
      },//表具类型
      { 
        path: '/archives',
        component: () => import('@/views/Basics/archives.vue')
      },//热表档案
      { 
        path: '/collector',
        component: () => import('@/views/Basics/collector.vue')
      },//采集器
      {
        path: '/daily',
        component: () => import('@/views/Dosage/daily.vue')
      },//日用量
      {
        path: '/usage',
        component: () => import('@/views/Dosage/usage.vue')
      },//月用量
      {
        path: '/Annual',
        component: () => import('@/views/Dosage/Annual.vue')
      },//年用量
    ]
  }

]


const router = new VueRouter({
  routes
})

export default router