<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  created() {
  }
}
</script>

<style lang="scss">
  html,body,h3 {
    
    margin: 0;
    padding: 0;
    
  }
  #id{
    background-color: aqua;
  }
</style>
