<template>
    <div id="app">
     <!-- 面包屑 -->
    <!-- <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>基础资料</el-breadcrumb-item>
        <el-breadcrumb-item>位置信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <!-- li -->
    <div class="lisai">
      <div class="modular">
        <div class="query">
          <span>小区</span>
          <el-input placeholder="请搜索" size="mini" v-model.trim="comities.search_content" clearable @clear="QingChu"></el-input>
          <el-button size="mini" @click="table">查询</el-button>
          <el-button size="mini" @click="residentialbtn">添加</el-button>
        </div>
        <div class="tableData">
        <el-card>
          <el-table
          :data="tableData"
          size=mini
          highlight-current-row
          @current-change="handleCurrentChange"
          :header-cell-style = "{background:'#df6124'}"
          height='400'>
            <el-table-column type="index" width="40" />
            <el-table-column property="name" label="小区名称" :show-overflow-tooltip="true" />
            <el-table-column property="valve" label="操作" width="150">
              <template slot-scope="scope">
                <el-button size="mini" @click="Cellbtn(scope.row)">修改</el-button>
                <el-button size="mini" @click="Celldeletion(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        </div>
      </div>
      <div class="modular">
        <div class="query">
          <span>楼栋</span>
          <el-input placeholder="请搜索" size="mini" v-model.trim="buings.search_content" clearable @clear="QingChu"></el-input>
          <el-button size="mini" @click="Building">查询</el-button>
          <el-button size="mini" @click="Buildingbtn">添加</el-button>
        </div>
        <div class="tableData">
        <el-card>
          <el-table
          :data="Buildings"
          size=mini
          highlight-current-row
          @current-change="selectBuildings"
          :header-cell-style = "{background:'#df6124'}"
          height='400'>
            <el-table-column type="index" width="40" />
            <el-table-column property="name" label="楼栋名称" :show-overflow-tooltip="true" />
            <el-table-column property="valve" label="操作" width="150">
              <template slot-scope="scope">
                <el-button size="mini" @click="Buildingmodification(scope.row)">修改</el-button>
                <el-button size="mini" @click="Buildingdeletion(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        </div>
      </div>
      <div class="modular">
        <div class="query">
          <span>单元</span>
          <el-input placeholder="请搜索" size="mini" v-model.trim="unistfs.search_content" clearable @clear="QingChu"></el-input>
          <el-button size="mini" @click="unit">查询</el-button>
          <el-button size="mini" @click="unitbtn">添加</el-button>
        </div>
        <div class="tableData">
        <el-card>
          <el-table
          :data="units"
          size=mini
          highlight-current-row
          @current-change="selectunit"
          :header-cell-style = "{background:'#df6124'}"
          height='400'>
            <el-table-column type="index" width="40" />
            <el-table-column property="name" label="单元名称" :show-overflow-tooltip="true" />
            <el-table-column property="valve" label="操作" width="150">
              <template slot-scope="scope">
                <el-button size="mini" @click="Unitmodifbtn(scope.row)">修改</el-button>
                <el-button size="mini" @click="Unitmodaleta(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        </div>
      </div>
      <div class="modular">
        <div class="query">
          <span>房间</span>
          <el-input placeholder="请搜索" size="mini" v-model.trim="roomfs.search_content" clearable @clear="QingChu"></el-input>
          <el-button size="mini" @click="room">查询</el-button>
          <el-button size="mini" @click="roombtn">添加</el-button>
        </div>
        <div class="tableData">
        <el-card>
          <el-table
          :data="rooms"
          size=mini
          highlight-current-row
          :header-cell-style = "{background:'#df6124'}"
          height='400'>
            <el-table-column type="index" width="40" />
            <el-table-column property="name" label="房间名称" :show-overflow-tooltip="true" />
            <el-table-column property="valve" label="操作" width="150">
              <template slot-scope="scope">
                <el-button size="mini" @click="roommodify(scope.row)">修改</el-button>
                <el-button size="mini" @click="Roomdelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        </div>
      </div>
    </div>
    <!-- 修改弹框 -->
    <div>
      <!-- 修改小区弹框 -->
  <div class="locationAdd">
  <el-dialog title="信息修改" :visible.sync="commfication">
  <el-form :model="modify">
    <el-form-item label="小区名称 : " :label-width="formLabelWidth" :required="true">
      <el-input v-model.trim="modify.name" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="Locaficat"><i class="el-icon-document" />保存</el-button>
  </div>
</el-dialog>
  </div>
  <!-- 修改楼栋弹框 -->
  <div class="locationAdd">
  <el-dialog title="信息修改" :visible.sync="Buildingfication">
  <el-form :model="Buildingmodi">
    <el-form-item label="选择小区:" :label-width="formLabelWidth" :required="true">
          <el-select v-model="Buildingmodi.community_id" clearable placeholder="请选择小区"
          @change="selectmodify(Buildingmodi.community_id)">
            <el-option
                v-for="item in tableData"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          </el-form-item>
    <el-form-item label="楼栋名称: " :label-width="formLabelWidth">
      <el-input v-model.trim="Buildingmodi.name" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="Buildingficat"><i class="el-icon-document" />保存</el-button>
  </div>
</el-dialog>
  </div>
  <!-- 修改单元弹框 -->
  <div class="locationAdd">
  <el-dialog title="信息修改" :visible.sync="Unitfication">
  <el-form :model="Unitcation">
    <el-form-item label="选择小区:" :label-width="formLabelWidth" :required="true">
          <el-select v-model="Unitcation.community_id" clearable placeholder="请选择小区"
          @change="selectUnit(Unitcation.community_id)">
            <el-option
                v-for="item in tableData"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="选择楼栋:" :label-width="formLabelWidth">
          <el-select v-model="Unitcation.building_id" clearable placeholder="请选择小区"
          @change="selectBuildingsmodify(Unitcation.building_id)">
            <el-option
                v-for="item in Buildings"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          </el-form-item>
    <el-form-item label="单元名称 : " :label-width="formLabelWidth">
      <el-input v-model.trim="Unitcation.name" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="Unitmodificationbtn"><i class="el-icon-document" />保存</el-button>
  </div>
</el-dialog>
  </div>
  <!-- 修改房间弹框 -->
  <div class="locationAdd">
  <el-dialog title="信息修改" :visible.sync="roomfication">
  <el-form :model="Roommodification">
     <el-form-item label="选择小区:">
          <el-select v-model="Roommodification.community_id" clearable placeholder="请选择小区"
          @change="modifyselectQuarters(Roommodification.community_id)">
            <el-option
                v-for="item in tableData"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="楼栋名称:">
            <el-select v-model="Roommodification.building_id" placeholder="请选择楼栋"
            @change="modifyselectBuildings(Roommodification.building_id)">
              <el-option
                v-for="item in Buildings"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="单元名称:">
            <el-select v-model="Roommodification.unit_id" placeholder="请选择楼栋"
            @change="modifyselectunit(Roommodification.unit_id)">
              <el-option
                v-for="item in units"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
    <el-form-item label="房间名称: ">
      <el-input v-model.trim="Roommodification.name" autocomplete="off" style="width: 230px;"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="roomLocaficat"><i class="el-icon-document" />保存</el-button>
  </div>
    </el-dialog>
  </div>
    </div>
    <!-- 添加弹框 -->
    <div>
    <!-- 添加小区弹框 -->
  <div class="locationAdd">
  <el-dialog title="信息编辑" :visible.sync="residentialVisible">
  <el-form :model="residentialparameter">
    <el-form-item label="小区名称 : " :label-width="formLabelWidth" :required="true">
      <el-input v-model.trim="residentialparameter.name" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="Cellsave"><i class="el-icon-document" />保存</el-button>
  </div>
</el-dialog>
  </div>
  <!-- 添加楼栋弹框 -->
  <div class="locationAdd">
  <el-dialog title="信息编辑" :visible.sync="BuildingVisible">
  <el-form :model="Buildingparameters">
    <el-form-item label="楼栋名称 : " :label-width="formLabelWidth" :required="true">
      <el-input v-model.trim="Buildingparameters.name" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="Buildingpreservation"><i class="el-icon-document" />保存</el-button>
  </div>
</el-dialog>
  </div>
     <!-- 添加单元弹框 -->
  <div class="locationAdd">
  <el-dialog title="信息编辑" :visible.sync="unitVisible">
  <el-form :model="Cellparameters">
    <el-form-item label="单元名称 : " :label-width="formLabelWidth" :required="true">
      <el-input v-model.trim="Cellparameters.name" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="Unitsave"><i class="el-icon-document" />保存</el-button>
  </div>
</el-dialog>
  </div>
  <!-- 添加房间弹框 -->
  <div class="locationAdd">
  <el-dialog title="信息编辑" :visible.sync="roomVisible">
  <el-form :model="Roomparameters">
    <el-form-item label="房间名称 : " :label-width="formLabelWidth" :required="true">
      <el-input v-model.trim="Roomparameters.name" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="Roompreservation"><i class="el-icon-document" />保存</el-button>
  </div>
</el-dialog>
</div>
    </div>
    </div>
</template>
<script>// 小区楼栋单元房间的数据，小区添加修改删除，楼栋添加修改删除，单元添加修改删除，房间添加修改删除
import { communities, buildings, units, rooms, putcommunities, postcommunities, deletecommunities, putbuildings, postbuildings, deletebuildings, putunits, postunits, deleteunits, putrooms, postrooms, deleterooms } from '../../api/userProfile'
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      // 添加
      roomVisible: false,
      unitVisible: false,
      BuildingVisible: false,
      residentialVisible: false,
      // 修改
      roomfication: false,
      Unitfication: false,
      commfication: false,
      Buildingfication: false,
      formLabelWidth: '100px',

      tableData: [{
        id: null,
        name: null
      }],
      Buildings: [{
        id: null,
        name: null
      }],
      units: [
        {
          id: null,
          name: null
        }
      ],
      rooms: [],
      // 小区
      comities: {
        token: null,
        search_content: null
      },
      // 修改
      modify: {
        token: null,
        name: null,
        community_id: null
      },
      // 删除
      deleteloca: {
        token: null,
        community_id: null
      },
      // 添加参数
      residentialparameter: {
        token: null,
        name: null
      },
      // 楼栋
      // bulidshow: false,
      munity: null,
      buings: {
        token: null,
        community_id: null,
        search_content: null
      },
      // 修改楼栋
      Buildingmodi: {
        token: null,
        name: null,
        community_id: null,
        building_id: null
      },
      Buildidelet: {
        token: null,
        building_id: null
      },
      // 添加
      Buildingparameters: {
        token: null,
        name: null,
        community_id: null
      },
      // 单元
      // modushow: false,
      unistfs: {
        token: null,
        building_id: null,
        search_content: null
      },
      // 修改
      Unitcation: {
        token: null,
        building_id: null,
        unit_id: null,
        name: null
      },
      // 删除
      Unitcatdelete: {
        token: null,
        unit_id: null
      },
      // 添加
      Cellparameters: {
        token: null,
        name: null,
        building_id: null
      },
      // 房间
      // foomshow: false,
      roomfs: {
        token: null,
        unit_id: null,
        search_content: null
      },
      // 修改
      Roommodification: {
        token: null,
        unit_id: null,
        name: null
      },
      Roomdeleteloca: {
        token: null,
        room_id: null
      },
      // 添加
      Roomparameters: {
        token: null,
        name: null,
        unit_id: null
      }
    }
  },
  created () {
    this.table()
    this.Buildings = null
    this.units = null
  },
  methods: {
    // 输入框清除
    QingChu () {
      console.log('112')
    },
    // 获取小区
    table () {
      this.comities.token = Cookies.get('token')
     communities(this.comities).then((res) => {
        if (res.data.status === 'success') {
          console.log(res.data.data, '获取小区')
          this.tableData = res.data.data
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
          this.$router.push('/')
        }
      })
    },
    handleCurrentChange (val) {
      console.log(val)
      this.munity = val.id
      // this.bulidshow = true
      // this.modushow = false
      // this.foomshow = false
      this.units = null
      this.rooms = null
      this.Building()
    },
    // 修改小区
    Cellbtn (row) {
      console.log(row)
      this.xigai = row.id
      this.commfication = true
    },
    Locaficat () {
      this.modify.token = Cookies.get('token')
      this.modify.community_id = this.xigai
      postcommunities(this.modify).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '修改')
          this.table()
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
      this.commfication = false
      this.modify.name = null
    },
    // 删除
    Celldeletion (row) {
      this.deleteloca.token = Cookies.get('token')
      this.deleteloca.community_id = row.id
      this.$confirm('此操作将永久删除该位置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
       deletecommunities(this.deleteloca).then((res) => {
          if (res.data.status === 'success') {
            console.log(res, '删除')
            this.table()
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            })
          } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 添加小区
    residentialbtn () {
      this.residentialVisible = true
    },
    Cellsave () {
      this.residentialparameter.token = Cookies.get('token')
      putcommunities(this.residentialparameter).then((res) => {
        console.log(res, '添加')
        if (res.data.status === 'success') {
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          })
          this.table()
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
      this.residentialVisible = false
      this.residentialparameter.name = null
    },
    // 获取楼栋
    Building () {
      this.buings.token = Cookies.get('token')
      this.buings.community_id = this.munity
      buildings(this.buings).then((res) => {
        console.log(res.data.data, '获取楼栋')
        this.Buildings = res.data.data
      })
    },
    selectBuildings (val) {
      console.log(val, 'val2')
      this.unis = val.id
      // this.modushow = true
      // this.foomshow = false
      this.rooms = null
      this.unit()
    },
    // 修改楼栋
    Buildingmodification (row) {
      console.log(row)
      this.Buildingmodi.building_id = row.id
      this.Buildingfication = true
    },
    // 修改
    selectmodify (val) {
      console.log(val, 'val')
      this.modify.community_id = val
      // this.Building()
    },
    Buildingficat () {
      this.Buildingmodi.token = Cookies.get('token')
      // this.modify.building_id = this.ooo
     postbuildings(this.Buildingmodi).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '修改')
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          })
          this.Building()
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
      this.Buildingfication = false
      this.Buildingmodi.name = null
    },
    // 楼栋删除
    Buildingdeletion (row) {
      console.log(row, '删除')
      this.Buildidelet.token = Cookies.get('token')
      this.Buildidelet.building_id = row.id
      this.$confirm('此操作将永久删除该位置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deletebuildings(this.Buildidelet).then((res) => {
          if (res.data.status === 'success') {
            console.log(res, '删除')
            this.Building()
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            })
          } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 添加
    Buildingbtn () {
      this.BuildingVisible = true
    },
    Buildingpreservation () {
      this.Buildingparameters.token = Cookies.get('token')
      this.Buildingparameters.community_id = this.munity
     putbuildings(this.Buildingparameters).then((res) => {
        console.log(res, '添加')
        if (res.data.status === 'success') {
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          })
          this.Building()
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
      this.BuildingVisible = false
      this.Buildingparameters.name = null
    },
    // 单元
    unit () {
      this.unistfs.token = Cookies.get('token')
      this.unistfs.building_id = this.unis
      units(this.unistfs).then((res) => {
        console.log(res.data.data, '获取单元')
        this.units = res.data.data
      })
    },
    selectunit (val) {
      console.log(val, 'val3')
      this.rom = val.id
      // this.foomshow = true
      this.room()
    },
    // 修改
    Unitmodifbtn (row) {
      this.Unitcation.building_id = row.building_id
      this.Unitcation.unit_id = row.id
      this.Unitfication = true
    },
    // 选择小区
    selectUnit (val) {
      console.log(val)
      this.munity = val
      this.Building()
    },
    // 选择楼栋
    selectBuildingsmodify (val) {
      console.log(val, 'val')
      this.Unitcation.building_id = val
    },
    Unitmodificationbtn () {
      this.Unitcation.token = Cookies.get('token')
      postunits(this.Unitcation).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '修改')
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          })
          this.unit()
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
      this.Unitfication = false
      this.Unitcation.name = null
    },
    // 删除
    Unitmodaleta (row) {
      console.log(row, '删除')
      this.Unitcatdelete.token = Cookies.get('token')
      this.Unitcatdelete.unit_id = row.id
      this.$confirm('此操作将永久删除该位置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteunits(this.Unitcatdelete).then((res) => {
          if (res.data.status === 'success') {
            console.log(res, '删除')
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            })
            this.unit()
          } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 添加
    unitbtn () {
      this.unitVisible = true
    },
    // 添加保存
    Unitsave () {
      this.Cellparameters.token = Cookies.get('token')
      this.Cellparameters.building_id = this.unis
      putunits(this.Cellparameters).then((res) => {
        console.log(res, '添加')
        if (res.data.status === 'success') {
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          })
          this.unit()
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        } else if (res.data.message === '用户在别处登录') {
          this.$router.push('/')
        }
      })
      this.unitVisible = false
      this.Cellparameters.name = null
    },
    // 房间
    room () {
      this.roomfs.token = Cookies.get('token')
      this.roomfs.unit_id = this.rom
      rooms(this.roomfs).then((res) => {
        console.log(res.data.data, '获取房间')
        this.rooms = res.data.data
      })
    },
    // 修改
    roommodify (row) {
      console.log(row, 'row')
      this.Roommodification.unit_id = row.unit_id
      this.Roommodification.room_id = row.id
      this.roomfication = true
    },
    // 修改小区
    modifyselectQuarters (val) {
      console.log(val, 'val')
      this.munity = val
      this.Building()
    },
    // 修改楼栋
    modifyselectBuildings (val) {
      console.log(val, 'val')
      this.unis = val
      this.unit()
    },
    // 单元
    modifyselectunit (val) {
      console.log(val, 'val')
      this.Roommodification.unit_id = val
    },
    // 修改保存
    roomLocaficat () {
      this.Roommodification.token = Cookies.get('token')
      postrooms(this.Roommodification).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '修改')
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          })
          this.room()
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        } else if (res.data.message === '用户在别处登录') {
          this.$router.push('/')
        }
      })
      this.roomfication = false
      this.Roommodification.name = null
    },
    // 删除
    Roomdelete (row) {
      console.log(row, '删除')
      this.Roomdeleteloca.token = Cookies.get('token')
      this.Roomdeleteloca.room_id = row.id
      this.$confirm('此操作将永久删除该位置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      deleterooms(this.Roomdeleteloca).then((res) => {
          if (res.data.status === 'success') {
            console.log(res, '删除')
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            })
            this.room()
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
          } else if (res.data.message === '用户在别处登录') {
            this.$router.push('/')
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 添加
    roombtn () {
      this.roomVisible = true
    },
    // 添加保存
    Roompreservation () {
      this.Roomparameters.token = Cookies.get('token')
      this.Roomparameters.unit_id = this.rom
      putrooms(this.Roomparameters).then((res) => {
        console.log(res, '添加')
        if (res.data.status === 'success') {
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          })
          this.room()
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        } else if (res.data.message === '用户在别处登录') {
          this.$router.push('/')
        }
      })
      this.roomVisible = false
      this.Roomparameters.name = null
    }
  }
}
</script>
<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
.lisai{
//   margin: 20px auto;
//   width: 90%;
  display: flex;
  flex-wrap: wrap;
  .modular{
    width: 21%;
    height: 500px;
    border: 1px solid #df6124;
    border-radius: 10px;
    padding: 20px 10px 0px 10px;
    margin-left: 20px;
    .query{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-input{
        width: 200px;
      }
      .el-button{
        background-color: #df6124;
        color: #fff;
      }
    }
    .tableData{
        margin-top: 20px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
        .el-button{
          border: 1px solid #df6124;
        }
    }
    }
}
.locationAdd{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 20%;
    }
  }
  .el-button{
    border: 1px solid #C0353A;
    background-color: #C0353A;
  }
  }
 // 移动端
 @media (max-width: 768px) {
  .Gnv{
    display: none;
  }
  .lisai{
    margin: 10px auto;
    .modular{
      margin-top: 20px;
      margin-left: 0px;
      width: 92%;
    }
  }
  .locationAdd{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 94%;
    }
  }
  }
 }
</style>
