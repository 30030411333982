<template>
  <div class="header-container">
    <div class="l-content">
      <el-button @click="handleMenu" icon="el-icon-menu" size="mini"></el-button>
      <!-- 面包屑 -->
      <span class="text">热力系统</span>
    </div>
    <div class="r-content">
    <el-dropdown trigger="click" @command="handleClick">
      <span class="el-dropdown-link">  
        <img class="user" src="../assets/images/user.png" alt="">
      </span>
      <el-dropdown-menu slot="dropdown">
        <!-- <el-dropdown-item>个人中心</el-dropdown-item> -->
        <el-dropdown-item command="dengchu">退出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
</div>
  </div>
</template>
<script>
import Cookie from 'js-cookie'
export default {
  data() {
    return {};
  },
  methods: {
    handleMenu() {
        this.$store.commit('collapseMenu')
    },
    handleClick(command){
        if(command === 'dengchu'){
            console.log('登出')
            // 退出清除cookie中的token
            Cookie.remove('token')
            this.$router.push('/')
        }
    }
  }
};
</script>
<style lang="less" scoped>
.header-container {
  padding: 0 20px;
  background-color: #E0E0E0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .text {
    color: #df6124;
    font-weight: 500;
    font-size: 16px;
    margin-left: 14px;
  }
  .r-content{
    .user{
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
  }
}
</style>