import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookies from 'js-cookie'
import ElementUI from 'element-ui'
//elementui的样式
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/common.scss'
// 引入
import * as echarts from 'echarts';
Vue.config.productionTip = false

Vue.use(ElementUI)
// 挂载
Vue.prototype.$echarts = echarts
//添加全局前置导航守卫
router.beforeEach((to, from, next) => {
  // 先判断token存不存在，在Cookie中读取token在不在 ，引入Cookie
  const token = Cookies.get('token')
  // token不存在，说明当前用户是未登录，应该跳转至登录页
  if (!token && to.name !== 'login') {
    next({ name: 'login' })
  } else if(token && to.name === 'login'){
    next({ name: 'Home' })
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
