<template>
    <div>
        <el-container>
            <el-aside width="auot">
                <Aside />
            </el-aside>
            <el-container>
                <el-header>
                    <Header />
                </el-header>
                <el-main><router-view/></el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import Aside from '../components/Aside.vue'
import Header from '../components/Header.vue'
export default {
    components: {
        Aside,
        Header
    },
    data() {
        return {}
    },
}
</script>

<style lang="less" scoped>
.el-header{
    padding: 0; 
}
.el-main{
    // background-color: #;
}
</style>