import request from "../utils/request";
// 获取小区
export function communities (data) {
    return request({
      method: 'get',
      url: 'communities',
      params: data
    })
  }
// 小区的添加
export function putcommunities (data) {
    return request({
      method: 'POST',
      url: 'communities',
      data
    })
  }
  
  // 小区的修改
  export function postcommunities (data) {
    return request({
      method: 'put',
      url: 'communities',
      data
    })
  }
  
  // 小区的删除
  export function deletecommunities (data) {
    return request({
      method: 'DELETE',
      url: 'communities',
      data
    })
  }
  
// 获取楼栋
export function buildings (data) {
    return request({
      method: 'get',
      url: 'buildings',
      params: data
    })
  }
  // 楼栋的添加
  export function putbuildings (data) {
    return request({
      method: 'POST',
      url: 'buildings',
      data
    })
  }
  
  // 楼栋的修改
  export function postbuildings (data) {
    return request({
      method: 'put',
      url: 'buildings',
      data
    })
  }
  
  // 楼栋的删除
  export function deletebuildings (data) {
    return request({
      method: 'DELETE',
      url: 'buildings',
      data
    })
  }
  
  // 获取单元
  export function units (data) {
    return request({
      method: 'get',
      url: 'units',
      params: data
    })
  }
  // 单元的添加
  export function putunits (data) {
    return request({
      method: 'post',
      url: 'units',
      data
    })
  }
  
  // 单元的修改
  export function postunits (data) {
    return request({
      method: 'put',
      url: 'units',
      data
    })
  }
  
  // 单元的删除
  export function deleteunits (data) {
    return request({
      method: 'DELETE',
      url: 'units',
      data
    })
  }

  // 获取房间
  export function rooms (data) {
    return request({
      method: 'get',
      url: 'rooms',
      params: data
    })
  }
  // 房间的添加
  export function putrooms (data) {
    return request({
      method: 'post',
      url: 'rooms',
      data
    })
  }
  
  // 房间的修改
  export function postrooms (data) {
    return request({
      method: 'put',
      url: 'rooms',
      data
    })
  }
  
  // 房间的删除
  export function deleterooms (data) {
    return request({
      method: 'DELETE',
      url: 'rooms',
      data
    })
  }
